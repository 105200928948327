import { Image } from 'antd';
import React from 'react';

const SectorSelectionIcon = () => {
  return (
    <Image src='images/icons/house.svg' height={'30px'} width={'30px'} style={{marginTop:'-2px'}}/>
  );
};


export default SectorSelectionIcon;

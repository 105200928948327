import React from 'react';
import { Button, Typography } from 'antd';
import PropTypes from 'prop-types';

const TerrainTypeButton = ({ buttonText,isActive,height,handleClick,color,width,textColor,icon }) => {
  const buttonStyle = {
    width: width??'100%',
    height: height??'60px',
    //flexShrink: 0,
    
    backgroundColor: color,
    //filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom:'5px',
    borderRadius: '15px'
  };
  const { Title } = Typography;

  return (
    <Button style={buttonStyle}  onClick={handleClick} disabled={!isActive??true}>
      <div style={{ display: 'flex',  alignItems: 'flex-end' }}>
       
       
          <Title level={5} style={{ color: textColor?? '#FFF', fontStyle: 'normal', fontWeight: '700',textAlign:'center',marginTop:'5px' }}>
            {buttonText} {icon??''}
          </Title>
        
      </div>
    </Button>
  );
};

TerrainTypeButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  color:PropTypes.string,
  isActive: PropTypes.bool,
  height:PropTypes.string,
  handleClick: PropTypes.func,
  width:PropTypes.string,
  textColor:PropTypes.string,
  icon:PropTypes.element,
};

export default TerrainTypeButton;

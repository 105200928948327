import React from 'react';
import { Card, Row } from 'antd';
import PropTypes from 'prop-types';
import SectorTitleButton from '../Button/SectorTitleButton';

const SummaryCard = ({ title, gridContent,onClick }) => {
  const gridStyle = {
    width: '100%',
    borderRadius: '15px',
        background: 'rgba(0, 105, 80, 0.01)',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        cursor: 'pointer',
  };

  return (
    <Card
    
    style={{
        borderRadius: '15px',
        background: 'rgba(0, 105, 80, 0.01)',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    }}
    title={
        <SectorTitleButton
        handleClick={onClick}
          backgroundColor={"#008D6B40"}
          textColor={"#2F2F2F"}
          buttonText={title}
          isActive={true}
          fontSize={'16px'}
        />}
    >
      <Card.Grid style={gridStyle} onClick={onClick}>
        <div>
          <Row style={{ marginTop: '20px', width: '100%' }}>
            {gridContent}
          </Row>
          <div style={{ marginBottom: '70px' }}></div>
        </div>
      </Card.Grid>
    </Card>
  );
};

SummaryCard.propTypes = {
  title: PropTypes.string, // Node is used for accepting any renderable React child
  gridContent: PropTypes.node,
  onClick: PropTypes.func,
};

export default SummaryCard;

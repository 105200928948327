import React from 'react';
import { Button, Typography } from 'antd';
import PropTypes from 'prop-types';

const TitleV2Button = ({ buttonText,secondText, icon,isActive,height,handleClick }) => {
  const buttonStyle = {
    width: '600px',
    height: height??'80px',
    flexShrink: 0,
    
    backgroundColor: '#00678A',
    //filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin:'10px'
  };
  const { Title } = Typography;

  return (
    <Button style={buttonStyle} icon={icon} onClick={handleClick} disabled={!isActive??true}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%',flexDirection:'column' }}>
       
       
          <Title level={2} style={{ color: '#FFF', fontStyle: 'normal', fontWeight: '700',textAlign:'center',marginTop:'10px' }}>
            {buttonText}
          </Title>
          <Title level={2} style={{ color: '#FFF', fontStyle: 'normal', fontWeight: '700',textAlign:'center',marginTop:'-20px' }}>
            {secondText}
          </Title>
          
        
      </div>
    </Button>
  );
};

TitleV2Button.propTypes = {
  buttonText: PropTypes.string.isRequired,
  secondText: PropTypes.string,
  icon: PropTypes.element,
  isActive: PropTypes.bool,
  height:PropTypes.string,
  handleClick: PropTypes.func
};

export default TitleV2Button;

import React from 'react';
import { Card, Row } from 'antd';
import ServiceItem from '../Items/ServiceItem';
import PropTypes from 'prop-types';


const ServiceCard = ({ items, handleItemClick,title,child }) => {
  return (
    <Card style={{ background: "rgba(0, 44, 60, 0.10)", flex: 1 }}>
      <div>
        <div
          style={{
            color: "#00678A",
            fontSize: "20px",
            fontWeight: "700",
            marginBottom: "20px",
          }}
        >
         {title}
        </div>
        {child}

        <Row>
          {items.map((item) => (
            <ServiceItem
              isActive={item.isActive}
              key={item.id}
              text={item.text}
              checked={item.checked}
              onChange={() => handleItemClick(item.id)}
            />
          ))}
        </Row>
      </div>
    </Card>
  );
};

ServiceCard.propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        isActive: PropTypes.bool.isRequired,
        text: PropTypes.string.isRequired,
        checked: PropTypes.bool.isRequired,
      })
    ).isRequired,
    handleItemClick: PropTypes.func,
    title: PropTypes.string,
    child: PropTypes.any
  };
export default ServiceCard;

// WeatherSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';

export const weatherSlice = createSlice({
    name: 'weather',
    initialState: {
        permittedChoices: ["a", "b", "c", "ad", "bd", "cd", "ae", "be", "ce", "ade", "bde", "cde", ""],
        weatherConditions: [
            { id: 1, text: "Mostly Sunny", checked: false, color: "#CD9400", isActive: true },
            { id: 2, text: "Mostly Rainy", checked: false, color: "#929292", isActive: true },
            { id: 3, text: "Mixed (Rainy/Sunny)", checked: false, color: "#072B71", isActive: true },
            { id: 4, text: "Foggy", checked: false, color: "#072B71", isActive: true },
            { id: 5, text: " Freezing Temperatures", checked: false, color: "#072B71", isActive: true },
        ],
        jsonWeather:{
            "type": "string",
            "result":""

        },
        isAnyWeatherChecked: false
    },
    reducers: {
        toggleWeatherCondition: (state, action) => {
            const idToLetterMap = {
                1: "a",
                2: "b",
                3: "c",
                4: "d",
                5: "e"

            };
            const { id } = action.payload;
            const weatherItem = state.weatherConditions.find(item => item.id === id);
            if (weatherItem) {
                weatherItem.checked = !weatherItem.checked;
            }
            const selectedConditions = state.weatherConditions.filter(item => item.checked).map(item => idToLetterMap[item.id]);
            const selectedString = selectedConditions.join("");
            if (!state.permittedChoices.includes(selectedString)) {
                
                message.error("The selected combination is not permitted.");
                weatherItem.checked = !weatherItem.checked;
              }
              state.isAnyWeatherChecked =  state.weatherConditions.some(condition => condition.checked);
              console.log(state.isAnyWeatherChecked);

              const selectedWeather = state.weatherConditions.map(item => item.checked ? 1 : 0);
        state.jsonWeather = {
            
          type: "string",
          result: selectedWeather
        };  
            
        },
    },
});

export const { toggleWeatherCondition } = weatherSlice.actions;

export const selectWeatherConditions = state => state.weather.weatherConditions;
export const selectJsonWeather = state => state.weather.jsonWeather;
export const selectIsAnyWeatherChecked = state => state.weather.isAnyWeatherChecked;
export default weatherSlice.reducer;

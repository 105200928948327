import React from 'react';
import { Row, Col, Card, Typography } from 'antd';

const { Title } = Typography;

const EnvironmentalMonitoringCanvas = () => {
  const data = {
    keyPartners: [
      'Internet service providers ISP',
      'Technology and Data providers',
      'Application providers (data analytics)',
      'Financial providers (e.g. banks)',
      'Government and Regulatory bodies',
    ],
    keyActivities: ['Periodic data transmission of the water quality parameters in the environment'],
    valuePropositions: [
      'Human resource and operations optimization and cost reduction',
      'Reduce the overall impact of operations on the environment',
    ],
    customerRelationships: ['Personal assistance', 'Co-creation (tailor-made)'],
    customerSegments: ['SMEs', 'Local community', 'Environmental agencies'],
    channels: ['Social media channels', 'Website'],
    keyResources: [
      'Human resources (farmer, cloud/software/hardware/API engineers)',
      'Physical resources (water quality sensors, LoRaWaN transmitter/gateway, edge devices)',
    ],
    costStructure: ['Fixed costs for Infrastructure and maintenance', 'Operational costs'],
  };

  return (
    <div>
      <Title level={2}>Water Quality Monitoring </Title>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card title="Key Partners">
            <ul>
              {data.keyPartners.map((partner, index) => (
                <li key={index}>{partner}</li>
              ))}
            </ul>
          </Card>
        </Col>

        <Col span={24}>
          <Card title="Key Activities">
            <ul>
              {data.keyActivities.map((activity, index) => (
                <li key={index}>{activity}</li>
              ))}
            </ul>
          </Card>
        </Col>

        <Col span={24}>
          <Card title="Value Propositions">
            <ul>
              {data.valuePropositions.map((proposition, index) => (
                <li key={index}>{proposition}</li>
              ))}
            </ul>
          </Card>
        </Col>

        <Col span={24}>
          <Card title="Customer Relationships">
            <ul>
              {data.customerRelationships.map((relationship, index) => (
                <li key={index}>{relationship}</li>
              ))}
            </ul>
          </Card>
        </Col>

        <Col span={24}>
          <Card title="Customer Segments">
            <ul>
              {data.customerSegments.map((segment, index) => (
                <li key={index}>{segment}</li>
              ))}
            </ul>
          </Card>
        </Col>

        <Col span={24}>
          <Card title="Channels">
            <ul>
              {data.channels.map((channel, index) => (
                <li key={index}>{channel}</li>
              ))}
            </ul>
          </Card>
        </Col>

        <Col span={24}>
          <Card title="Key Resources">
            <ul>
              {data.keyResources.map((resource, index) => (
                <li key={index}>{resource}</li>
              ))}
            </ul>
          </Card>
        </Col>

        <Col span={24}>
          <Card title="Cost Structure">
            <ul>
              {data.costStructure.map((cost, index) => (
                <li key={index}>{cost}</li>
              ))}
            </ul>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default EnvironmentalMonitoringCanvas;

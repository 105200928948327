import React from 'react';
import PropTypes from 'prop-types';
const DIcon = ({iconName,height,width}) => {
    const icon = '/images/sector-icons/'+iconName;
  return (
    <img src={icon} height={height??'30px'} width={width??'30px'} style={{marginTop:'-2px'}}/>
  );
};


DIcon.propTypes = {
    iconName: PropTypes.string.isRequired,
    height: PropTypes.string,
    width: PropTypes.string
    
  };
  
export default DIcon;

import { Image } from 'antd';
import React from 'react';

const EvaluateIcon = () => {
  return (
    <div style={{display:'flex',flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"}}>
         <Image src='images/icons/graph1.png' height={'30px'} style={{marginTop:'-5px'}}/>
        <Image src='images/icons/graph2.png' height={'30px'} style={{marginTop:'-5px'}}/>
    </div>
   
    
  );
};


export default EvaluateIcon;

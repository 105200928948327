// levelSlice.js 

import { createSlice } from '@reduxjs/toolkit';

// Define the name of the slice for easier identification within the Redux store
export const levelSlice = createSlice({
  name: 'level',
  initialState: {
    // Array of objects representing the different assessment levels
    levels: [
      { id: 1, text: "Local", checked: false, color: "#6C9D60", isActive: false },
      { id: 2, text: "Community", checked: false, color: "#145216", isActive: false },
      { id: 3, text: "Regional", checked: false, color: "#774B23", isActive: false },
    ],
    // Object containing user types and their associations with assessment levels
    userTypes: {
      data: [
        { id: 1, text: "End-User", checked: false, isActive: false }, // Descriptive text for user type
        { id: 2, text: "Public Authority", checked: false, isActive: false },
        { id: 3, text: "Internet Service Provider (ISP)", checked: false, isActive: false },
      ],
      Local: [1], // User IDs associated with Local level
      Community: [1, 2, 3], // User IDs associated with Community level
      Regional: [2, 3], // User IDs associated with Regional level
    },
    // Object to store user type selection data in JSON format
    jsonUserType: {},
    jsonLevel: {
       "type": "string",
            "result":""
    },
    // Flag indicating if any user type is checked
    isAnyUserTypeChecked: false,
    // Currently selected assessment level (text)
    currentLevel: "",
  },
  reducers: {
    toggleUserType: (state, action) => {
        const { id } = action.payload;
        const userTypeItem = state.userTypes.data.find(item => item.id === id);
        if (userTypeItem) {
            userTypeItem.checked = !userTypeItem.checked;
            state.userTypes.data.forEach(item => item.checked = item.id === id);
        }
        // Check if any user type is currently checked
      state.isAnyUserTypeChecked = state.userTypes.data.some(condition => condition.checked);
      const selectedUserType= state.userTypes.data.find(userType => userType.checked === true);
       // Update the jsonUserType object with the selected user type data
       state.jsonUserType = {
        // Descriptive property name for clarity
          type: "text", // Data type of the selection
          result: selectedUserType.text, // The actual selected user type list
        
      };
    },
    toggleLevel: (state, action) => {
      const { id } = action.payload; // Extract the ID from the payload

      // Find the level item in the state based on the provided ID
      const levelItem = state.levels.find(item => item.id === id);

      state.userTypes.data.forEach(item => item.checked = false);

      if (levelItem) {
        // Toggle the isActive flag for the clicked level
        levelItem.isActive = !levelItem.isActive;
        state.levels.forEach(item => item.isActive = item.id === id);

        if (levelItem.isActive) {
          // Get the user IDs associated with the selected level
          const ids = state.userTypes[levelItem.text];

          // Update the current level
          state.currentLevel = levelItem.text;

          // Update the isActive flag for user types based on their association with the selected level
          state.userTypes.data = state.userTypes.data.map((item) => ({
            ...item,
            isActive: ids.includes(item.id),
          }));
        } else {
          // If the level is deactivated, set all user types to inactive
          state.userTypes.data = state.userTypes.data.map((item) => ({
            ...item,
            isActive: false,
          }));
        }
      }

     
       // Check if any user type is currently checked
       state.isAnyUserTypeChecked = state.userTypes.data.some(condition => condition.checked);
     

      const selectedLevel = state.levels.find(level => level.isActive === true);
      // Update the jsonUserType object with the selected user type data
      state.jsonLevel = {
       // Descriptive property name for clarity
         type: "text", // Data type of the selection
         result: selectedLevel.text, // The actual selected user type list
       
     };

     
    },
  },
});

// Export the toggleLevel action creator
export const { toggleLevel,toggleUserType } = levelSlice.actions;

// Define selector functions to access specific parts of the state
export const selectLevels = state => state.level.levels;
export const selectUserTypes = state => state.level.userTypes.data;
export const selectJsonUserType = state => state.level.jsonUserType;
export const selectJsonLevel = state => state.level.jsonLevel;
export const isForestChecked = state => state.level.isForestChecked; // Potentially for future use
export const selectIsAnyUserTypeChecked = state => state.level.isAnyUserTypeChecked; // Potentially for future use

// Export the reducer for use in the Redux store
export default levelSlice.reducer;

import React, { useEffect, useState } from "react";
import AppContent from "../../components/Layouts/AppContent";
import HomeLayout from "../../components/Layouts/HomeLayout";
import { useNavigate } from "react-router-dom";
import {  Col, Row ,message} from "antd";
import TitleButton from "../../components/Button/TitleButton";
import paths from "../../routes/route";
import ServiceIcon from "../../components/Icons/ServiceIcon";
import ImpactCard from "../../components/Cards/ImpactCard";

import ComputeResultButton from "../../components/Button/ComputeResultButton";

import { postSolutionsAnalysis } from "../../services/inc_api_service";
import CustomModal from "../../components/Modals/CustomModal";




export default function ImpactAssessment() {
  const navigate = useNavigate();
  useEffect(() => {
    // Check if policies are accepted
    const policyAccepted = localStorage.getItem("policyAccepted");

    // If policies are not accepted, redirect to the policy acceptance page
    if (!policyAccepted) {
      navigate("/");
    }
  }, [navigate]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const closeModal = () => {
    setIsModalVisible(false);
    navigate(paths.RESULT.SUGGESTED_TECHNOLOGY);
  };
  

  const items = [
    { label: "Technological", value: 50 },
    { label: "Techno-economic", value: 50 },
    { label: "Socio-economic", value: 50 },
    { label: "Environmental", value: 50 },
  ];

  const handleConfirmButtonClick = () => {
    postSolutionsAnalysis();
    // Show the ongoing operation alert
    const ongoingAlert = message.loading('Operation in progress...', 0);

    // Set a timeout to show the success alert after 30 seconds
    const timer = setTimeout(() => {
      ongoingAlert(); // Close the ongoing operation alert
      setIsLoading(false);
      setIsModalVisible(true);
      navigate(paths.RESULT.SUGGESTED_TECHNOLOGY);
    }, 3000);

    // Clean up the timer if the component unmounts before the timer finishes
    return () => {
      clearTimeout(timer);
      ongoingAlert(); // Close the ongoing operation alert
    };
   
  };
  

  return (
    <HomeLayout>
      <AppContent
        width={"80%"}
        title={
          <div>
            <TitleButton
              icon={<ServiceIcon size={"40px"} />}
              buttonText="Impact Weights"
              isActive={true}
            />
           
          </div>
        }
      >
        <Row
          style={{ width: "70vw" }}
          gutter={[16, 16]}
          justify="center"
          align="middle"
        >
          <Col
            span={24}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></Col>

          <Row style={{ width: "100%" }}>
            <ImpactCard
              title={
                "Please move the sliders to assign weights to each impact assessment below"
              }
              items={items}
            />
          </Row>

        </Row>
        
       
    <CustomModal
        isVisible={isModalVisible}
        onClose={closeModal}
        isLoading={isLoading}
        //title="Custom Title"
       // message="This is a custom message."
        width={500}
        closeButtonText="Done"
      />
          <ComputeResultButton
            isActive={true }
            handleClick={() => handleConfirmButtonClick()}
          />
        
      </AppContent>
    </HomeLayout>
  );
}

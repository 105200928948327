import { Image } from 'antd';
import React from 'react';

const ServiceSelectionIcon = () => {
  return (
    <Image src='images/icons/pencil.png' height={'30px'} width={'30px'} style={{marginTop:'2px'}}/>
  );
};


export default ServiceSelectionIcon;

import React, { useEffect, useState } from "react";
import { Table } from 'antd';
import ICCSRESPONSEDATA from '../../utility/jsonICCSDATa';

const NetworkTable = () => {
    const [tableData, setTableData] = useState([]);

    const getProcessDevInfo = (data) => {
        const defaultLayers = ["Extreme", "Cloud", "Far", "Near"];
        return defaultLayers.reduce((acc, layer) => {
            const index = data.All_layers.indexOf(layer);
            acc[layer] = {
                process_dev_info: index !== -1 ? data.Process_Dev_per_layer[index] : [],
                number: index !== -1 ? data.Number[index] : []
            };
            return acc;
        }, {});
    };

    // Function to get connectivity information from the provided data
const getConnectivityInfo = (data) => {
    // Default layers to check in the data
    const defaultLayers = ["Local", "Access", "Internet"];
    
    // Reduce the defaultLayers array to an object with connectivity information for each layer
    return defaultLayers.reduce((acc, layer) => {
        // Find the index of the current layer in the data.Links array
        const index = data.Links.indexOf(layer);
        
        // Populate the acc object with net and number arrays for the current layer
        acc[layer] = {
            net: index !== -1 ? data.Nets[index] : [],      // If layer exists, use corresponding data.Nets element, otherwise use an empty array
            number: index !== -1 ? data.Number[index] : []  // If layer exists, use corresponding data.Number element, otherwise use an empty array
        };
        
        return acc;  // Return the accumulated object
    }, {});
};


    const getData = () => {
        const response = JSON.parse(ICCSRESPONSEDATA());
        if (response && response.results) {
            const firstThreeSolutions = Object.values(response.results).slice(0, 3);
            const newTableData = firstThreeSolutions.map((solution, index) => {
                const connectivity = solution.Connectivity_information;
                const end_dev = solution.End_dev_information;
                const processing = solution.Processing_information;
                const processInfo = getProcessDevInfo(processing);
                const connectivityInfo = getConnectivityInfo(connectivity);

                return {
                    key: index + 1,
                    index: index + 1,
                    accessConnectivity: connectivityInfo.Access.net,
                    no1: connectivityInfo.Access.number,
                    localConnectivity: connectivityInfo.Local.net,
                    no2: connectivityInfo.Local.number,
                    internetConnectivity: connectivityInfo.Internet.net,
                    no3: connectivityInfo.Internet.number,
                    extreme: processInfo.Extreme.process_dev_info,
                    noExtreme: processInfo.Extreme.number,
                    far: processInfo.Far.process_dev_info,
                    noFar: processInfo.Far.number,
                    near: processInfo.Near.process_dev_info,
                    noNear: processInfo.Near.number,
                    cloud: processInfo.Cloud.process_dev_info,
                    noCloud: processInfo.Cloud.number,
                    endDevices: end_dev.Type.join(','),
                    noDevices: end_dev.Number.join(','),
                    warning: connectivity.Disclaimer,
                };
            });
            setTableData(newTableData);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 50,
        },
        {
            title: 'Networks',
            children: [
                {
                    title: 'Access Connectivity',
                    dataIndex: 'accessConnectivity',
                    key: 'accessConnectivity',
                    width: 50,
                },
                {
                    title: 'No',
                    dataIndex: 'no1',
                    key: 'no1',
                    width: 50,
                },
                {
                    title: 'Local Connectivity',
                    dataIndex: 'localConnectivity',
                    key: 'localConnectivity',
                },
                {
                    title: 'No',
                    dataIndex: 'no2',
                    key: 'no2',
                    width: 50,
                },
                {
                    title: '(Public) Internet \n Connectivity',
                    dataIndex: 'internetConnectivity',
                    key: 'internetConnectivity',
                },
                {
                    title: 'No',
                    dataIndex: 'no3',
                    key: 'no3',
                    width: 50,
                },
            ],
        },
        {
            title: 'Edge Enablers',
            children: [
                {
                    title: 'Extreme',
                    dataIndex: 'extreme',
                    key: 'extreme',
                },
                {
                    title: 'No',
                    dataIndex: 'noExtreme',
                    key: 'noExtreme',
                    width: 50,
                },
                {
                    title: 'Far',
                    dataIndex: 'far',
                    key: 'far',
                },
                {
                    title: 'No',
                    dataIndex: 'noFar',
                    key: 'noFar',
                    width: 50,
                },
                {
                    title: 'Near',
                    dataIndex: 'near',
                    key: 'near',
                },
                {
                    title: 'No',
                    dataIndex: 'noNear',
                    key: 'noNear',
                    width: 50,
                },
                {
                    title: 'Cloud',
                    dataIndex: 'cloud',
                    key: 'cloud',
                },
                {
                    title: 'No',
                    dataIndex: 'noCloud',
                    key: 'noCloud',
                    width: 50,
                },
            ],
        },
        {
            title: 'End-devices',
            children: [
                {
                    title: 'Type',
                    dataIndex: 'endDevices',
                    key: 'endDevices',
                },
                {
                    title: 'No',
                    dataIndex: 'noDevices',
                    key: 'noDevices',
                    width: 50,
                },
            ]
        },
        {
            title: '*Warning',
            dataIndex: 'warning',
            key: 'warning',
        },
    ];

    return (
        <Table 
            columns={columns} 
            dataSource={tableData} 
            bordered 
            size="small" 
            pagination={false}
        />
    );
};

export default NetworkTable;
// TerrainSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';



export const questionSlice = createSlice({
    name: 'question',
    initialState: {
        currentQuestion: {
            key: "dev_per_type"
        },
        previousQuestion: {
            key: "dev_per_type"
        },
        isEndOfQuestion: false,
        apiBody:{
            
               //location: { type: "text", result: "" },
               // Area: { type: "number", result: "" },
               // Terrain: { type: "list", result: [0, 0, 0, 0, 0] },
                //Vegetation_height: { type: "string", result: 0 },
               // Weather: { type: "list", result: [0, 0, 0, 0, 0] },
                dev_per_type: { type: "list", result: [0, 0, 0, 0, 0] },
                sensor_rate: { type: "list", result: [0, 0] },
                sensor_freq: { type: "list", result: [0, 0, 0, 0] },
                sensor_lat: { type: "list", result: [0, 0] },
                type_of_drones: { type: "list", result: [0] },
                drone_service: { type: "list", result: [0] },
                drone_altitude: { type: "list", result: [0] },
                drone_image_proc: { type: "list", result: [0] },
                drone_image_lat: { type: "list", result: [0] },
                drone_iot_lat: { type: "list", result: [0] },
                personal_dev_type: { type: "string", result: [0] },
                tablet_internet: { type: "string", result: [0] },
                tablet_image_proc: { type: "string", result: [0] },
                tablet_image_lat: { type: "string", result: [0] },
                tablet_rate_down: { type: "string", result: [0] },
                laptop_internet: { type: "string", result: [0] },
                laptop_image_proc: { type: "string", result: [0] },
                laptop_image_lat: { type: "string", result: [0] },
                laptop_rate_down: { type: "string", result: [0] },
                personal_internet: { type: "string", result: [0] },
                personal_proc: { type: "string", result: [0] },
                personal_image_lat: { type: "string", result: [0] },
                personal_rate_down: { type: "string", result: [0] },
                camera_rate: { type: "string", result: [0] },
                camera_proc: { type: "string", result: [0] },
                camera_image_lat: { type: "string", result: [0] },
                camera_image_freq: { type: "string", result: [0] },
                robot_type: { type: "string", result: "" },
                robot_service: { type: "string", result: [0] },
                robot_image_rate: { type: "string", result: [0] },
                robot_image_freq: { type: "string", result: [0] },
                robot_image_proc: { type: "string", result: [0] },
                robot_image_lat: { type: "string", result: [0] },
                robot_power: { type: "string", result: 0 },
                robot_cost: { type: "string", result: 0 },
                robot_iot_rate: { type: "string", result: [0] },
                robot_iot_freq: { type: "string", result: [0] },
                robot_iot_proc: { type: "string", result: [0] },
                robot_iot_lat: { type: "string", result: [0] },
                robot_speech_freq: { type: "string", result: [0] },
                robot_speech_proc: { type: "string", result: [0] },
                robot_speech_lat: { type: "string", result: [0] },
               // sector: { type: "text", result: "" },
               // service: { type: "text", result: "" },
               // user_type_selection: { type: "text", result: "" },
               // level_of_assessment: { type: "text", result: "" },
              
        },
        responses: {
            /*start: {
                id: "dev_per_type",
                type: "list",
                result: "",
            },*/
            sensor_rate: { type: 'list', result: [0, 0] },
            sensor_freq: {
                id: "sensor_rate",
                type: "list",
                result: "",

            },
            dev_per_type: { type: 'list', result: [0, 0, 0, 0, 0] },

        },
        questions: {
            dev_per_type: {
                text: "What type of device do you want to employ for deploying your service?",
                choices: [
                    { id: 1, text: "Sensors", checked: false, nextQuestion: "sensor_rate" },
                    { id: 2, text: "Drones", checked: false, nextQuestion: "type_of_drones" },
                    { id: 3, text: "Personal Devices (Smartphones / Tablets / Laptops)", checked: false, nextQuestion: 'personal_dev_type' },
                    { id: 4, text: "Cameras", checked: false, nextQuestion: "camera_rate" },
                    { id: 5, text: "Other type of device", checked: false, nextQuestion: "robot_type" },
                ],
                input: {
                    label: "Specify number of devices employed",
                    value: "",
                    nextQuestion: "", // Initialize with an empty string
                }
            },

            sensor_rate: {
                text: "What sensor readings do you want to produce?",
                choices: [
                    { id: 1, text: "Simple readings (e.g. soil humidity, temperature, etc.)", checked: false, nextQuestion: "sensor_freq" },
                    { id: 2, text: "Complex readings (gas concentration, soil nutrient sensors, etc.)", checked: false, nextQuestion: "sensor_freq" }
                ]
            },
            sensor_freq: {
                text: "In which order of magnitude are sensor readings produced?",
                choices: [
                    { id: 1, text: "Once per second", checked: false, nextQuestion: "sensor_lat" },
                    { id: 2, text: "Once per minute", checked: false, nextQuestion: "sensor_lat" },
                    { id: 3, text: "Once per hour", checked: false, nextQuestion: "sensor_lat" },
                    { id: 4, text: "Once per day or less", checked: false, nextQuestion: "sensor_lat" }
                ]
            },
            sensor_lat: {
                text: "Can the data be processed with some delay, or does it require immediate action?",
                choices: [
                    { id: 1, text: "An immediate action is necessary (<= 2 s)", checked: false, nextQuestion: "end" },
                    { id: 2, text: "It can be processed with delay (no specific requirement)", checked: false, nextQuestion: "end" }
                ]
            },
            type_of_drones: {
                text: "What type of Drones are you planning on deploying?",
                choices: [
                    { id: 1, text: "Rotor-based drones", checked: false, nextQuestion: "drone_service" },
                    { id: 1, text: "Wing-based drones", checked: false, nextQuestion: "drone_service" }
                ]
            },
            drone_service: {
                text: "What type of data is produced by your device? In case multiple options apply, select only the first one that applies in the list.",
                choices: [
                    { id: 1, text: "Images/Videos from a camera", checked: false, nextQuestion: "drone_altitude" },
                    { id: 2, text: "Sensor readings", checked: false, nextQuestion: "drone_iot_lat" }
                ]
            },
            drone_altitude: {
                text: "At what altitude do you plan on recording footage?",
                choices: [
                    { id: 1, text: "High-altitudes (100-250m)", checked: false, nextQuestion: "drone_image_proc" },
                    { id: 2, text: "Medium altitudes (30-100m)", checked: false, nextQuestion: "drone_image_proc" },
                    { id: 3, text: "Low altitudes (< 30m)", checked: false, nextQuestion: "drone_image_proc" }
                ]
            },
            drone_image_proc: {
                text: "What type of digital processing needs to be done with the data produced by your device?",
                choices: [
                    { id: 1, text: "Image processing (e.g. object detection and recognition, image analysis)", checked: false, nextQuestion: "drone_image_lat" },
                    { id: 2, text: "No processing requirements (only transmission of data)", checked: false, nextQuestion: "drone_image_lat" }
                ]
            },
            drone_image_lat: {
                text: "How fast does the captured video have to be processed?",
                choices: [
                    { id: 1, text: "In real time (< 200 ms)", checked: false, nextQuestion: "end" },
                    { id: 2, text: "Quick, e.g. for ad-hoc visualization purposes ( <= 3 s)", checked: false, nextQuestion: "end" },
                    { id: 3, text: "No specific requirement (> 3 s)", checked: false, nextQuestion: "end" }
                ]
            },
            drone_iot_lat: {
                text: "Can the data be processed with some delay, or does it require immediate action?",
                choices: [
                    { id: 'a', text: "An immediate action is necessary (<= 2 s)", checked: false, nextQuestion: "end" },
                    { id: 'b', text: "It can be processed with delay (no specific requirement)", checked: false, nextQuestion: "end" }
                ]
            },
            personal_dev_type: {
                text: "What type of personal device will you be using? Please provide the number of each.",
                choices: [
                    { id: 'a', text: "Smartphones / Tablets", checked: false, nextQuestion: "tablet_internet" },
                    { id: 'b', text: "Laptops", checked: false, nextQuestion: "laptop_internet" }
                ],
                input: {
                    label: "Number of devices",
                    value: "" // Initialize with an empty string
                }
            },
            tablet_internet: {
                text: "Will you be uploading videos from your personal device?",
                choices: [
                    { id: 1, text: "Yes", checked: false, nextQuestion: "tablet_image_proc" },
                    { id: 2, text: "No", checked: false, nextQuestion: "tablet_rate_down" }
                ]
            },
            tablet_image_proc: {
                text: "What type of digital processing needs to be done with the data produced by your device?",
                choices: [
                    { id: 1, text: "Image processing (e.g. object detection and recognition, image analysis)", checked: false, nextQuestion: "tablet_image_lat" },
                    { id: 2, text: "No processing requirements (only transmission of data)", checked: false, nextQuestion: "tablet_rate_down" }
                ]
            },
            tablet_image_lat: {
                text: "How fast does the captured video have to be processed?",
                choices: [
                    { id: 1, text: "In real time (< 200 ms)", checked: false, nextQuestion: "tablet_rate_down" },
                    { id: 2, text: "Quick, e.g. for ad-hoc visualization purposes ( <= 3 s)", checked: false, nextQuestion: "tablet_rate_down" },
                    { id: 3, text: "No specific requirement (> 3 s)", checked: false, nextQuestion: "tablet_rate_down" }
                ]
            },
            tablet_rate_down: {
                text: "Will you be consuming content on your devices? If so which category of content? Select the first one that applies.",
                choices: [
                    { id: 1, text: "High resolution videos", checked: false, nextQuestion: "end" },
                    { id: 2, text: "Browsing the web", checked: false, nextQuestion: "end" },
                    { id: 3, text: "Music streaming", checked: false, nextQuestion: "end" }
                ]
            },
            personal_internet: {
                text: "Will you be uploading videos from your personal device?",
                choices: [
                    { id: 1, text: "Yes", checked: false, nextQuestion: "laptop_image_proc" },
                    { id: 2, text: "No", checked: false, nextQuestion: "laptop_rate_down"  }
                ]
            },
            laptop_internet: {
                text: "Will you be uploading videos from your personal device?",
                choices: [
                    { id: 1, text: "Yes", checked: false, nextQuestion: "laptop_image_proc" },
                    { id: 2, text: "No", checked: false, nextQuestion: "laptop_rate_down"  }
                ]
            },
            laptop_image_proc: {
                text: "What type of digital processing needs to be done with the data produced by your device?",
                choices: [
                    { id: 1, text: "Image processing (e.g. object detection and recognition, image analysis)", checked: false, nextQuestion: "tablet_image_lat" },
                    { id: 2, text: "No processing requirements (only transmission of data)", checked: false, nextQuestion: "tablet_rate_down" }
                ]
            },
            laptop_image_lat: {
                text: "How fast does the captured video have to be processed?",
                choices: [
                    { id: 1, text: "In real time (< 200 ms)", checked: false, nextQuestion: "tablet_rate_down" },
                    { id: 2, text: "Quick, e.g. for ad-hoc visualization purposes ( <= 3 s)", checked: false, nextQuestion: "tablet_rate_down" },
                    { id: 3, text: "No specific requirement (> 3 s)", checked: false, nextQuestion: "tablet_rate_down" }
                ]
            },
            laptop_rate_down: {
                text: "Will you be consuming content on your devices? If so which category of content? Select the first one that applies.",
                choices: [
                    { id: 1, text: "High resolution videos", checked: false, nextQuestion: "end" },
                    { id: 2, text: "Browsing the web", checked: false, nextQuestion: "end" },
                    { id: 3, text: "Music streaming", checked: false, nextQuestion: "end" }
                ]
            },
            camera_rate: {
                text: "What type of cameras do you want to set up?",
                choices: [
                    { id: 1, text: "Low-medium resolution cameras (e.g. for counting livestock, surveillance, etc.)", checked: false, nextQuestion: "camera_proc" },
                    { id: 2, text: "High-resolution cameras (Crop monitoring, timelapse photography, etc.)", checked: false, nextQuestion: "camera_proc" },
                    { id: 3, text: "Infrared / Thermal Imaging (Water stress detection, livestock monitoring, night operation, etc.)", checked: false, nextQuestion: "camera_proc" }
                ]
            },
            camera_proc: {
                text: "What type of digital processing needs to be done with the data produced by your device?",
                choices: [
                    { id: 1, text: "Image processing (e.g. object detection and recognition, image analysis)", checked: false, nextQuestion: "camera_image_lat" },
                    { id: 2, text: "No processing requirements (only transmission of data)", checked: false, nextQuestion: "camera_image_freq" }
                ]
            },
            camera_image_lat: {
                text: "How fast does the captured video have to be processed?",
                choices: [
                    { id: 1, text: "In real time (< 200 ms)", checked: false, nextQuestion: "camera_image_freq" },
                    { id: 2, text: "Quick, e.g. for ad-hoc visualization purposes ( <= 3 s)", checked: false, nextQuestion: "camera_image_freq" },
                    { id: 3, text: "No specific requirement (> 3 s)", checked: false, nextQuestion: "camera_image_freq" }
                ]
            },
            camera_image_freq: {
                text: "How fluid has the footage recorded to be (frames per second)?",
                choices: [
                    { id: 1, text: "High fluidity, e.g. 60 FPS", checked: false, nextQuestion: "end" },
                    { id: 2, text: "Medium fluidity, e.g. 30 FPS", checked: false, nextQuestion: "end" },
                    { id: 3, text: "Low fluidity, e.g. 15 FPS", checked: false, nextQuestion: "end" },
                    { id: 4, text: "Very low fluidity, e.g. 1 FPS", checked: false, nextQuestion: "end" }
                ]
            },
            robot_type: {
                text: "What type of personal device will you be using? Please provide the number of each.",
                choices: [


                ],
                input: {
                    nextQuestion: "robot_service",
                    label: "Specify type of device you want to deploy",
                    value: "" // Initialize with an empty string
                }
            },
            robot_service: {
                text: "What type of data is produced by your device?",
                choices: [
                    { id: 1, text: "Images/Video", checked: false, nextQuestion: "robot_image_rate" },
                    { id: 2, text: "IoT data", checked: false, nextQuestion: "robot_iot_rate" },
                    { id: 3, text: "Speech/audio samples", checked: false, nextQuestion: "robot_speech_freq" }
                ]
            },
            robot_image_rate: {
                text: "What type of cameras does your device incorporate?",
                choices: [
                    { id: 1, text: "Low-medium resolution cameras (e.g. for counting livestock, surveillance, etc.)", checked: false, nextQuestion: "robot_image_freq" },
                    { id: 2, text: "High-resolution cameras (Crop monitoring, timelapse photography, etc.)", checked: false },
                    { id: 3, text: "Infrared / Thermal Imaging (Water stress detection, livestock monitoring, night operation, etc.)", checked: false }
                ]
            },
            robot_image_freq: {
                text: "How fluid has the footage recorded to be (frames per second)?",
                choices: [
                    { id: 1, text: "High fluidity, e.g. 60 FPS", checked: false, nextQuestion: "robot_proc" },
                    { id: 2, text: "Medium fluidity, e.g. 30 FPS", checked: false, nextQuestion: "robot_proc" },
                    { id: 3, text: "Low fluidity, e.g. 15 FPS", checked: false, nextQuestion: "robot_proc" },
                    { id: 4, text: "Very low fluidity, e.g. 1 FPS", checked: false, nextQuestion: "robot_proc" }
                ]
            },
            robot_proc: {
                text: "What type of digital processing needs to be done with the data produced by your device?",
                choices: [
                    { id: 1, text: "Image processing (e.g. object detection and recognition, image analysis)", checked: false, nextQuestion: "robot_image_lat" },
                    { id: 2, text: "No processing requirements (only transmission of data)", checked: false, nextQuestion: "robot_power" }
                ]
            },
            robot_image_lat: {
                text: "How fast does the captured video have to be processed?",
                choices: [
                    { id: 1, text: "In real time (< 200 ms)", checked: false, nextQuestion: "robot_power" },
                    { id: 2, text: "Quick, e.g. for ad-hoc visualization purposes ( <= 3 s)", checked: false, nextQuestion: "camera_image_freq" },
                    { id: 3, text: "No specific requirement (> 3 s)", checked: false, nextQuestion: "camera_image_freq" }
                ]
            },
            robot_power: {
                text: "What is the power consumption of your device? ",
                choices: [],
                input: {
                    nextQuestion: "robot_cost",
                    label: "What is the power consumption of your device",
                    value: "" // Initialize with an empty string
                }
            },
            robot_cost: {
                text: "What is the cost of your device? ",
                choices: [],
                input: {
                    nextQuestion: "end",
                    label: "What is the cost of your device?",
                    value: "" // Initialize with an empty string
                }
            },
            robot_iot_freq: {
                text: "In which order of magnitude are sensor readings produced?",
                choices: [
                    { id: 1, text: "Once per second", checked: false,nextQuestion: "robot_iot_proc"  },
                    { id: 2, text: "Once per minute", checked: false,nextQuestion: "robot_iot_proc" },
                    { id: 3, text: "Once per hour", checked: false,nextQuestion: "robot_iot_proc" },
                    { id: 4, text: "Once per day or less", checked: false ,nextQuestion: "robot_iot_proc" }
                ]
            },
            robot_iot_rate: {
                text: "What sensor readings does your device produce?",
                choices: [
                    { id: 1, text: "Simple readings (e.g. soil humidity, temperature, etc.)", checked: false ,nextQuestion: "robot_iot_freq"},
                    { id: 2, text: "Complex readings (gas concentration, soil nutrient sensors, etc.)", checked: false,nextQuestion: "robot_iot_freq" }
                ]
            },
            robot_speech_freq: {
                text: "What is the type of recording?",
                choices: [
                    { id: 1, text: "For Podcast/audiobook", checked: false, nextQuestion: "robot_speech_proc" },
                    { id: 2, text: "For online meeting", checked: false, nextQuestion: "robot_speech_proc" },
                    { id: 3, text: "Personal recordings", checked: false, nextQuestion: "robot_speech_proc" }
                ]

            },
            robot_iot_proc: {

                text: "Does your data have to be processed or is it just transmitted?",
                choices: [
                    { id: 1, text: "Yes, it needs to be processed", checked: false,nextQuestion: "robot_power" },
                    { id: 2, text: "No, it only needs to be transmitted", checked: false, nextQuestion: "robot_power" }
                ]

            },
            robot_speech_proc: {

                text: "Does your data have to be processed or is it just transmitted?",
                choices: [
                    { id: 1, text: "Yes, it needs to be processed", checked: false,nextQuestion: "robot_speech_lat" },
                    { id: 2, text: "No, it only needs to be transmitted", checked: false, nextQuestion: "robot_power" }
                ]

            },
            robot_speech_lat: {
                text: "Can the data be processed with some delay, or does it require immediate action?",
                choices: [
                    { id: 1, text: "I expect a prompt reaction (<= 500 ms)", checked: false,nextQuestion: "robot_power" },
                    { id: 2, text: "It can be processed with some delay (no specific requirement)", checked: false,nextQuestion: "robot_power" }
                ]
            }




        },

        isForestChecked: false
    },
    reducers: {
        updateQuestion: (state, action) => {
            const { key, question } = action.payload;
            const choices = question.choices;
            console.log("demo");
      
            if (choices && choices.length > 0) {
              const resultArray = new Array(choices.length).fill(0);
              state.apiBody[key] = { type: "list", result: resultArray };
            } else if (question.input) {
                state.apiBody[key] = { type: "string", result: question.input.value };
            }
           
          },

          updateApiBody: (state,action) => {
            const [
              JSONSector,
              JSONService,
              JSONUserType,
              JSONLevel,
              JSONCountry,
              JSONAreaSize,
              JSONVegetationHeight,
              JSONTerrain,
              JSONWeather,
            ] = action.payload;
      
            state.apiBody.sector.result = JSONSector;
            state.apiBody.service.result = JSONService;
            state.apiBody.user_type_selection.result = JSONUserType;
            state.apiBody.level_of_assessment.result = JSONLevel;
            state.apiBody.location.result = JSONCountry;
            state.apiBody.Area.result = JSONAreaSize;
            state.apiBody.Vegetation_height.result = JSONVegetationHeight;
            state.apiBody.Terrain.result = JSONTerrain;
            state.apiBody.Weather.result = JSONWeather;
          },
        
        resetQuestion: (state) => {
            //  const { choice } = action.payload;
            state.currentQuestion.key = "dev_per_type";
            state.isEndOfQuestion = false;
            for (const questionKey in state.questions) {
                // Loop through each question in the `state.questions` object
              
                const question = state.questions[questionKey];
                // Get the current question object
              
                if (question.choices) {
                  // If the question has a `choices` array
                  question.choices.forEach(choice => {
                    // Loop through each choice in the `choices` array
                    choice.checked = false;
                    // Set the `checked` property of the choice to `false`
                  });
                }
              
                if (question.input) {
                  // If the question has an `input` object
                  question.input.value = "";
                  // Set the `value` property of the `input` object to an empty string
                }
              }
        },
         handleQuestionChoice : (state, questionKey, choice) => {
            //questionSlice.caseReducers.updateApiBody();
            const questionItem = state.questions[questionKey].choices.find(item => item.id === choice);
            //questionSlice.caseReducers.updateQuestion({key:questionKey,question:questionItem});
           

            state.questions[questionKey].choices.forEach((item, idx) => {
              item.checked = (idx === choice);
            });
            
            if (questionItem) {
              questionItem.checked = !questionItem.checked;
              
              if (questionItem.nextQuestion === "end") {
                state.isEndOfQuestion = true;
                console.log('End of questions');
              } else {
                state.previousQuestion.key = state.currentQuestion.key;
                state.currentQuestion.key = questionItem.nextQuestion;
              }
            }
            
           // return state;
          },

          toggleQuestion: (state, action) => {
            // Destructure the choice and isInput values from the action payload
            const { choice, isInput, value } = action.payload;
            // Get the current question key from the state
            const questionKey = state.currentQuestion.key;
            console.log(action.payload);
            // Get the choices array for the current question
            const choices = state.questions[questionKey].choices;
            console.log(state.questions[questionKey].input);
            console.log(questionKey);
           
        
            // Check if the current question has both choices and an input field
            if (choices.length > 0 && state.questions[questionKey].input != null) {
                console.log("number of devices");
                // If isInput is false, handle the choice selection
                if (!isInput) {
                    // Find the selected choice item based on the choice ID
                    const questionItem = state.questions[questionKey].choices.find(item => item.id == choice);
                    // Set the checked property of all other choices to false
                    choices.forEach((item, idx) => {
                        if (idx !== choice) {
                            item.checked = false;
                        }
                    });
                    // If the selected choice item exists
                    if (questionItem) {
                        // Toggle the checked property of the selected choice item
                        questionItem.checked = !questionItem.checked;
                        // Set the nextQuestion property of the input field to the nextQuestion of the selected choice item
                        state.questions[questionKey].input.nextQuestion = questionItem.nextQuestion;
                        console.log(questionItem.nextQuestion);
                    }
                } else {
                    // If isInput is true, handle the input field workflow
                    const questionItem = state.questions[questionKey].input;
                    console.log('input and questions');
                    state.questions[questionKey].input.value = value;
                    // If the nextQuestion property of the input field is empty, show an error message
                    if (questionItem.nextQuestion == "") {
                        message.error("please select one choice before set the value");
                    }
                    // If the nextQuestion property of the input field is "end", set isEndOfQuestion to true
                    else if (questionItem.nextQuestion == "end") {
                        state.isEndOfQuestion = true;
                        console.log('End of questions');
                    } else {
                        // Update the previous question key and the current question key based on the nextQuestion property of the input field
                        state.previousQuestion.key = state.currentQuestion.key;
                        state.currentQuestion.key = questionItem.nextQuestion;
                    }
                }
            }
            // Check if the current question has only choices and no input field
            else if (choices.length > 0 && state.questions[questionKey].input == null) {
                // Find the selected choice item based on the choice ID
                const questionItem = state.questions[questionKey].choices.find(item => item.id == choice);
                // Set the checked property of all other choices to false
                choices.forEach((item, idx) => {
                    if (idx !== choice) {
                        item.checked = false;
                    }
                });
                // If the selected choice item exists
                if (questionItem) {
                    // Toggle the checked property of the selected choice item
                    questionItem.checked = !questionItem.checked;
                }
                // If the nextQuestion property of the selected choice item is "end", set isEndOfQuestion to true
                if (questionItem.nextQuestion == "end") {
                    state.isEndOfQuestion = true;
                    console.log('End of questions');
                    //print(state.currentQuestion);
                } else {
                    // Update the previous question key and the current question key based on the nextQuestion property of the selected choice item
                    state.previousQuestion.key = state.currentQuestion.key;
                    state.currentQuestion.key = questionItem.nextQuestion;
                }
            } else {
                // If the current question has only an input field and no choices
                const questionItem = state.questions[questionKey].input;
                console.log('input and questions');
                // If the nextQuestion property of the input field is "end", set isEndOfQuestion to true
                if (questionItem.nextQuestion == "end") {
                    state.isEndOfQuestion = true;
                    console.log('End of questions');
                } else {
                    // Update the previous question key and the current question key based on the nextQuestion property of the input field
                    state.previousQuestion.key = state.currentQuestion.key;
                    state.currentQuestion.key = questionItem.nextQuestion;
                }
            }

            // const choices = questionItem.choices;
          // const questionItem = state.questions[questionKey].choices.find(item => item.id === choice);
          const question = state.questions[questionKey];
           if (choices && choices.length > 0 && question.input==null) {
            const resultArray = choices.map((choice) => (choice.checked ? 1 : 0));
            console.log(resultArray);
            
            state.apiBody[questionKey] = { type: "list", result: resultArray };
        } else if (question.input && choices) {
            const resultArray = choices.map((choice) => (choice.checked ? question.input.value : 0));
            state.apiBody[questionKey] = { type: "list", result: resultArray };
          }
              else if (question.input) {
                state.apiBody[questionKey] = { type: "string", result: question.input.value };
            }
        },
    },
});

export const { toggleQuestion, resetQuestion,updateApiBody } = questionSlice.actions;


export const selectQuestions = state => state.question.questions;
export const selectCurrentQuestion = state => state.question.currentQuestion;
export const selectPreviousQuestion = state => state.question.previousQuestion;
export const selectIsEndOfQuestion = state => state.question.isEndOfQuestion;
export const selectApiBody = state => state.question.apiBody

export default questionSlice.reducer;

import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";

const ComputeResultButton = ({ handleClick, isActive,textButton,width }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "10px",
        minWidth: '50vh',
        minHeight: "100px", // Set a minHeight for centering
      }}
    >
      <Button
        type="primary"
        onClick={handleClick}
        style={{
          minHeight: "60px",
          minWidth:width??'40vh',
          backgroundColor: isActive ? "black" : "rgba(0, 103, 138, 0.70)",
        }}
        disabled={!isActive ?? true}
      >
        <span style={{ color: "#FFF", padding: "5px", fontSize: "15px", fontWeight: "700" }}>
         {textButton??'Compute Result'}
        </span>
      </Button>
    </div>
  );
};

ComputeResultButton.propTypes = {
  icon: PropTypes.element,
  isActive: PropTypes.bool,
  handleClick: PropTypes.func,
  textButton: PropTypes.string,
  width: PropTypes.string
};

export default ComputeResultButton;

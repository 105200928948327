// areaSlice.js

import { createSlice } from '@reduxjs/toolkit';

/**
 * Redux slice for managing terrain area state.
 * @module areaSlice
 */

/**
 * Creates a Redux slice for area-related state management.
 * @type {import('@reduxjs/toolkit').Slice}
 */
export const areaSlice = createSlice({
  name: 'area',
  initialState: {
    /** @type {string} The size of the area */
    areaSize: '',
    /** @type {Object} JSON representation of the area size */
    jsonAreaSize: {
      type: "string",
      result: ""
    }
  },
  reducers: {
    /**
     * Sets the area size and updates the JSON representation.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} action.payload - The payload of the action.
     * @param {string|number} action.payload.value - The new area size value.
     */
    setAreaSize: (state, action) => {
      const { value } = action.payload;
      state.areaSize = value;
      state.jsonAreaSize = {
        type: "number",
        result: value
      };
    },
  },
});

// Export the action creator
export const { setAreaSize } = areaSlice.actions;

/**
 * Selector to get the current area size from the state.
 * @param {Object} state - The Redux state.
 * @returns {string} The current area size.
 */
export const selectAreaSize = state => state.area.areaSize;

// Export the reducer
export default areaSlice.reducer;
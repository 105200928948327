import React, { useEffect } from "react";
import AppContent from "../../components/Layouts/AppContent";
import HomeLayout from "../../components/Layouts/HomeLayout";
import { useNavigate } from "react-router-dom";
import {  Col, Row } from "antd";
import paths from "../../routes/route";
import ImpactActionRow from "../../components/Items/ImpactActionRow";
import EvaluateIcon from "../../components/Icons/EvaluateIcon";
import TitleV2Button from "../../components/Button/TitleV2Button";
import SummaryCard from "../../components/Cards/SummaryCard";
import FeedBackButton from "../../components/Button/FeedbackButton";


export default function SummaryResultPage() {
  const navigate = useNavigate();
  useEffect(() => {
    // Check if policies are accepted
    const policyAccepted = localStorage.getItem("policyAccepted");

    // If policies are not accepted, redirect to the policy acceptance page
    if (!policyAccepted) {
      navigate("/");
    }
  }, [navigate]);

 

  const handleConfirmButtonClick = () => {
    navigate(paths.SECTOR_SEVICES.STEP02);
  };

  const handleTechnicoButtonClick = () => {
    navigate(paths.SUB_RESULTS.TECHNO_ECONOMIC);
  };
  const handleSocioButtonClick = () => {
    navigate(paths.SUB_RESULTS.SOCIO_ENVIRONMENT);
  };
  const handleBusinessButtonClick = () => {
    navigate(paths.SUB_RESULTS.BUSINESS_MODEL_CANVA);
  };

  return (
    <HomeLayout>
      <AppContent
        width={"90%"}
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <TitleV2Button
              icon={<EvaluateIcon size={"40px"} />}
              buttonText="Summary Results "
              isActive={true}
            />
            <div style={{ marginTop: "20px" }}>
              <ImpactActionRow />
            </div>
          </div>
        }
      >
        <Row
          style={{ width: "80vw", marginTop: "20px" }}
          gutter={[16, 16]}
          justify="center"
          align="middle"
        >
          <Row style={{width:'100%'}} gutter={[16, 16]}>
            <Col span={8} style={{width:'100%'}}>
            <SummaryCard title={'Selected'}/>
            </Col>
            <Col span={16}>
             <SummaryCard title={'Connectivity & Edge Enablers'}/>
            </Col>
          </Row>
          <Row style={{width:'100%'}} gutter={[16, 16]}> 
            <Col span={8}>
            <SummaryCard title={'Techno-economic indicators'} onClick={handleTechnicoButtonClick}/>
            </Col>
            <Col span={8}>
            <SummaryCard title={'Socio-Environmental indicators'} onClick={handleSocioButtonClick} />
            </Col>
            <Col span={8}>
            <SummaryCard title={'Business Model'} onClick={handleBusinessButtonClick}/>
            </Col>
          </Row>
        
        </Row>

        <FeedBackButton
          isActive={true}
          textButton={"Proceed"}
          width={"20vh"}
          handleClick={() => handleConfirmButtonClick()}
        />
      </AppContent>
    </HomeLayout>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { Card,Row } from "antd";

const AppContent = ({ children,title,width }) => {
  const contentStyle = {
   
    flexShrink: 0,

    background: '#FFF',
    width:width??'70%',
   

    display: 'flex',
    flexDirection: "column",
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <Row justify="center" align="middle" style={{ minHeight: 'calc(50vh)',width:"100%" }}>
    <Card style={contentStyle} title={title} bordered={false}>
    { children }
    </Card>
    </Row>
  );
};

AppContent.propTypes = {
  children: PropTypes.node.isRequired,
  title:PropTypes.element,
  width: PropTypes.string
};

export default AppContent;

import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';

const ActionButton = ({ buttonText, icon, onClick }) => {
  return (
    <Button
      type="primary"
      style={{ backgroundColor: '#00678A', color: '#00a27b', minWidth:'80px', border: '1px solid #00678A', height: '70px',padding:'10px', display: 'flex', flexDirection: 'column', alignItems: 'center',margin:'5px' }}
      onClick={onClick}
    >
      <div style={{ fontSize: '14px', fontWeight: 'bold', color: 'white' }}>
        {buttonText}
      </div>
      <div style={{ marginTop: '4px' }}>
        {icon && React.cloneElement(icon, { style: { fontSize: '24px',color:'#fff' } })}
      </div>
    </Button>
  );
};

ActionButton.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.element,
  buttonText: PropTypes.string.isRequired,
};

export default ActionButton;

import React, { useState, useEffect } from 'react';
import { JSONTree } from 'react-json-tree';
import PropTypes from 'prop-types';

const JsonTreeView = ({ jsonData }) => {
    const [theme] = useState({
      base00: '#ffffff', // background
      base01: '#f5f5f5',
      base02: '#e0e0e0',
      base03: '#b0b0b0',
      base04: '#808080',
      base05: '#505050',
      base06: '#303030',
      base07: '#000000', // text
      base08: '#800000', // number
      base09: '#ff8000', // string
      base0A: '#808000', // boolean
      base0B: '#008000', // symbol
      base0C: '#008080', // key
      base0D: '#000080', // regex
      base0E: '#800080', // function
      base0F: '#008000'  // null
    });
  
    const [expandedPaths, setExpandedPaths] = useState([]);
  
    useEffect(() => {
      // Automatically expand the first two levels
      const paths = [];
      const expandFirstTwoLevels = (obj, path = []) => {
        if (typeof obj !== 'object' || obj === null) return;
        Object.keys(obj).forEach(key => {
          paths.push([...path, key]);
          if (path.length < 1) {
            expandFirstTwoLevels(obj[key], [...path, key]);
          }
        });
      };
      expandFirstTwoLevels(jsonData);
      setExpandedPaths(paths);
    }, [jsonData]);
  
    // Ensure jsonData is an object before rendering
    const dataToRender = typeof jsonData === 'object' && jsonData !== null ? jsonData : { data: jsonData };
  
    const cleanValue = (value) => {
      if (typeof value === 'string') {
        try {
          // Attempt to parse the string as JSON
          return JSON.parse(value);
        } catch (e) {
          // If it's not valid JSON, return the original string
          return value;
        }
      }
      return value;
    };
  
    return (
      <div style={{ 
        backgroundColor: theme.base00, 
        color: theme.base07,
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        fontFamily: 'monospace',
        fontSize: '14px'
      }}>
        <JSONTree 
          data={dataToRender} 
          theme={theme}
          invertTheme={false}
          shouldExpandNode={(keyPath) => 
            expandedPaths.some(path => 
              path.every((key, index) => key === keyPath[keyPath.length - 1 - index])
            )
          }
          getItemString={(type, data, itemType, itemString) => (
            <span style={{ color: theme.base0C }}>
              {itemType} {itemString}
            </span>
          )}
          labelRenderer={([key]) => (
            <span style={{ color: theme.base0D, fontWeight: 'bold' }}>
              {key}:
            </span>
          )}
          valueRenderer={(raw) => {
            const cleanedValue = cleanValue(raw);
            if (typeof cleanedValue === 'object' && cleanedValue !== null) {
              return <span>{'{...}'}</span>;
            }
            return (
              <span style={{ 
                color: 
                  typeof cleanedValue === 'string' ? theme.base09 :
                  typeof cleanedValue === 'number' ? theme.base08 :
                  typeof cleanedValue === 'boolean' ? theme.base0A :
                  cleanedValue === null ? theme.base0F :
                  theme.base07
              }}>
                {JSON.stringify(cleanedValue)}
              </span>
            );
          }}
        />
      </div>
    );
  };
JsonTreeView.propTypes = {
    
    jsonData:PropTypes.any
  };
export default JsonTreeView;
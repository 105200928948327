import React from 'react';
import {  Row, Col, Checkbox } from 'antd';
import PropTypes from 'prop-types';


const DynamicCheckCard = ({ items, handleItemClick, title, checkboxColumns }) => {
   
  return (
    <div>
      <div>
        <div
          style={{
            color: "#00678A",
            fontSize: "18px",
            fontWeight: "600",
            marginBottom: "40px",
          }}
        >
          {title}
        </div>

        <Row gutter={[16, 16]}>
          {items.map((item) => (
            <Col key={item.id} span={24 / checkboxColumns} >
              <Checkbox
                checked={item.checked}
                onChange={() => handleItemClick(item.id)}
                disabled={!item.isActive}
                style={{ display: 'flex', textAlign: 'start' }}
              >
                {item.text}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

DynamicCheckCard.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      isActive: PropTypes.bool.isRequired,
      text: PropTypes.string.isRequired,
      checked: PropTypes.bool.isRequired,
    })
  ).isRequired,
  handleItemClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  checkboxColumns: PropTypes.number.isRequired,
  checkedItems: PropTypes.object.isRequired, // Object to store checked state for each item
  setCheckedItems: PropTypes.func.isRequired, 
};

export default DynamicCheckCard;

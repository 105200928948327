import React from 'react';
import PropTypes from 'prop-types';

const CardText = ({ children,fontSize }) => {
  return (
    <div style={{ color: '#1D1D1D', fontSize: fontSize??'20px', fontWeight: '700', marginBottom: '20px' }}>
      {children}
    </div>
  );
};

CardText.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    fontSize:PropTypes.string
  };
export default CardText;

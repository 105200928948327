import React from 'react';
import { useNavigate } from "react-router-dom";
import paths from '../../routes/route';



const LogoIcon = () => {
  const navigate = useNavigate();
  const handleLocationButtonClick = ()=>{
    
   
    navigate(paths.HOME);

    
  }
  return (
    <img
      src='/images/icons/logo.png'
      alt="Logo"
      //height={'150px'}
      onClick={()=>handleLocationButtonClick()}
      style={{cursor:'pointer'}}
     
    />
  );
};

LogoIcon.propTypes = {
  
  
};

export default LogoIcon;

import React, { useEffect } from "react";
import AppContent from "../../components/Layouts/AppContent";
import HomeLayout from "../../components/Layouts/HomeLayout";
import { useNavigate } from "react-router-dom";
import { Card, Col, Row } from "antd";
import TitleButton from "../../components/Button/TitleButton";
import SectorTitleButton from "../../components/Button/SectorTitleButton";
import ConfirmButton from "../../components/Button/ConfirmButton";
import DIcon from "../../components/Icons/DIcon";
import paths from "../../routes/route";
import SectorIcon from "../../components/Icons/SectorIcon";
import LevelAssessmentButton from "../../components/Button/LevelAssessmentButton";
import UserTypeItem from "../../components/Items/UserTypeItem";
import { useDispatch, useSelector } from "react-redux";
import { selectIsAnyUserTypeChecked, selectLevels, selectUserTypes, toggleLevel, toggleUserType } from "../../reducers/levelSlice";

const gridStyle = {
  width: "100%",
  textAlign: "center",
  minHeight: '100%'
  //display:'flex',
  //justifyContent:'center',
};

export default function SectorServiceScreen02() {
  const navigate = useNavigate();
  useEffect(() => {
    // Check if policies are accepted
    const policyAccepted = localStorage.getItem("policyAccepted");

    // If policies are not accepted, redirect to the policy acceptance page
    if (!policyAccepted) {
      navigate("/");
    }
  }, [navigate]);
  const dispatch = useDispatch();
  const levels = useSelector(selectLevels);
  const userTypes = useSelector(selectUserTypes);
  const isAnyUserTypeChecked = useSelector(selectIsAnyUserTypeChecked);




  const handleConfirmButtonClick = () => {
    navigate(paths.SECTOR_SEVICES.INDEX);
  }

  const handleItemClick = (id) => {
    dispatch(toggleUserType({ id }));
  };

  const handleButtonClick = (id) => {
    dispatch(toggleLevel({ id }));
    console.log('click me');

  };



  return (
    <HomeLayout>
      <AppContent
        width={"90%"}
        title={
          <TitleButton
            icon={<SectorIcon size={"40px"} />}
            buttonText="Sector and service selection"
            isActive={true}
          />

        }
      >
        <Row style={{ width: "80vw" }} gutter={[16, 16]}>
          <Col span={12}>
            <Card
              justify="center" align="middle"
              title={
                <SectorTitleButton
                  icon={<DIcon iconName="analytics.svg" size={"40px"} />}
                  buttonText="Select Level of Assessment"
                  isActive={true}
                />
              }
            >
              <Card.Grid style={gridStyle} >
                <div
                //style={{ justifyContent: "center", alignContent: "center" }}
                >

                  <Row gutter={[16, 16]} justify="center" align="middle">
                    <Col span={24} justify="center" align="middle">
                      {levels.map((level, index) => (
                        <div key={index}>
                          <LevelAssessmentButton
                            buttonText={level.text}
                            isActive={level.isActive}
                            handleClick={() => handleButtonClick(level.id)}
                          />
                          <div style={{ marginBottom: "20px" }}></div>
                        </div>
                      ))}

                    </Col>

                  </Row>
                </div>
              </Card.Grid>
            </Card>
          </Col>
          <Col span={12}>
            <Card
              title={
                <SectorTitleButton
                  icon={<DIcon iconName="account-box.svg" size={"40px"} />}
                  buttonText="Select User Type"
                  isActive={true}
                />
              }
            >
              <Card.Grid style={gridStyle}>
                <div

                >
                  <Row >
                    {userTypes.map((item) => (
                      <UserTypeItem
                        isActive={item.isActive}
                        key={item.id}
                        // Replace with your icon
                        text={item.text}
                        checked={item.checked}
                        onChange={() => handleItemClick(item.id)}
                      />
                    ))}
                  </Row>
                  <div style={{ marginBottom: "70px" }}></div>
                </div>
              </Card.Grid>
            </Card>
          </Col>

        </Row>
        <ConfirmButton isActive={isAnyUserTypeChecked} handleClick={() => handleConfirmButtonClick()} />
      </AppContent>
    </HomeLayout>
  );
}

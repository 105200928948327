import React from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';
const AreaInput = ({value,onChange}) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <Input
        defaultValue = {value}
        placeholder="Enter value"
        onChange={(e) => onChange(e.target.value)}
        style={{ width: '100%', marginBottom: '8px' }}
      />
      <div style={{ color: '#1D1D1D', fontSize: '20px',fontWeight:'700' }}>Km<sup>2</sup></div>
    </div>
  );
};
AreaInput.propTypes = {
  //onSelect: PropTypes.func.isRequired,
  onChange: PropTypes.any,
  value: PropTypes.any
};

export default AreaInput;

import React, { useEffect, useState } from "react";
import AppContent from "../../components/Layouts/AppContent";
import HomeLayout from "../../components/Layouts/HomeLayout";
import { useNavigate } from "react-router-dom";
import {  Col, Row } from "antd";
import TitleButton from "../../components/Button/TitleButton";

import ConfirmButton from "../../components/Button/ConfirmButton";
import paths from "../../routes/route";
import CardText from "../../components/Texts/CardText";

import ServiceIcon from "../../components/Icons/ServiceIcon";
import ServiceCard from "../../components/Cards/ServiceCard";
import ViewSwitcher from "../../components/Button/ViewSwitcher";

export default function ServiceRequirementExpertView() {
  const navigate = useNavigate();
  useEffect(() => {
    // Check if policies are accepted
    const policyAccepted = localStorage.getItem("policyAccepted");

    // If policies are not accepted, redirect to the policy acceptance page
    if (!policyAccepted) {
      navigate("/");
    }
  }, [navigate]);



  const [latencyItems, setLatencyItems] = useState([
    {
      id: 1,
      text: "<= 10 ms",
      checked: false,
      isActive: true,
    },
    {
      id: 2,
      text: "<= 100 ms",
      checked: false,
      isActive: true,
    },
    {
      id: 3,
      text: "<= 500 ms",
      checked: false,
      icon: "fish-food.png",
      isActive: true,
    },
    {
        id: 4,
        text: "<= 1 s",
        checked: false,
        isActive: true,
      },
      {
        id: 5,
        text: "> 1 s",
        checked: false,
      
        isActive: true,
      },
      
  ]);

  const [speedItems, setSpeedItems] = useState([
    {
      id: 1,
      text: "<= 1 Mbps",
      checked: false,
      isActive: true,
    },
    {
      id: 2,
      text: "<= 5 Mbps",
      checked: false,
      isActive: true,
    },
    {
      id: 3,
      text: "<= 100 Mbps",
      checked: false,
      icon: "fish-food.png",
      isActive: true,
    },
    {
        id: 4,
        text: "<= 1 Gbps",
        checked: false,
        isActive: true,
      },
      {
        id: 5,
        text: "> 1 Gbps",
        checked: false,
      
        isActive: true,
      },
      
  ]);

  const [internetItems, setInternetItems] = useState([
    {
      id: 1,
      text: "Yes",
      checked: false,
      isActive: true,
    },
    {
      id: 2,
      text: "No",
      checked: false,
      isActive: true,
    },
   
  ]);
  const [privacyItems, setPrivacyItems] = useState([
    {
      id: 1,
      text: "Open / Shared",
      checked: false,
      isActive: true,
    },
    {
      id: 2,
      text: "Private",
      checked: false,
      isActive: true,
    },
   
  ]);

  const [technologyItems, setTechnologyItems] = useState([
    {
      id: 1,
      text: "Wi-Fi",
      checked: false,
      isActive: true,
    },
    {
      id: 2,
      text: "LoRa",
      checked: false,
      isActive: true,
    },
    {
      id: 3,
      text: "4G",
      checked: false,
      icon: "fish-food.png",
      isActive: true,
    },
    {
        id: 4,
        text: "5G",
        checked: false,
        isActive: true,
      },
      {
        id: 5,
        text: "Satellite",
        checked: false,
      
        isActive: true,
      },
      
  ]);

  const handleViewChange = (view) => {
    // Handle view change logic here
    console.log(`Switched to ${view} view`);
    navigate(paths.SERVICE.NORMAL_VIEW);
  };


 
 

 
  

 

 
  
  


    //nextStep(5);
  
  const handleLatencyItemClick = (id) => {
    setLatencyItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? { ...item, checked: !item.checked }
          : { ...item, checked: false }
      )
    );
  };
  const handleSpeedItemClick = (id) => {
    setSpeedItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? { ...item, checked: !item.checked }
          : { ...item, checked: false }
      )
    );
  };
  const handleTechnologyItemClick = (id) => {
    setTechnologyItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? { ...item, checked: !item.checked }
          : { ...item, checked: false }
      )
    );
  };
  const handleInternetItemClick = (id) => {
    setInternetItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? { ...item, checked: !item.checked }
          : { ...item, checked: false }
      )
    );
  };
  const handlePrivacyItemClick = (id) => {
    setPrivacyItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? { ...item, checked: !item.checked }
          : { ...item, checked: false }
      )
    );
  };

  

 


  const handleConfirmButtonClick = () => {
    navigate(paths.IMPACT_ASSESSMENT.INDEX);
  };
  
  return (
    <HomeLayout>
      <AppContent
        width={"80%"}
        title={
            <div> <TitleButton
            icon={<ServiceIcon size={"40px"} />}
            buttonText="Service Requirements"
            isActive={true}
          /> <ViewSwitcher onViewChange={handleViewChange}  view={'expert'}/></div>
         
        }
      >
        <CardText fontSize={"30px"}>Connectivity: Area A | Sensors</CardText>
        <Row
          style={{ width: "70vw",marginTop:'60px' }}
          gutter={[16, 16]}
          justify="center"
          align="middle"
        >
          <Col span={6} style={{ display: "flex", flexDirection: "column" }}>
            <ServiceCard
              title={"Latency"}
              items={latencyItems}
              handleItemClick={handleLatencyItemClick}
            />
          </Col>
          <Col span={6} style={{ display: "flex", flexDirection: "column" }}>
            <ServiceCard
              title={"Speed"}
              items={speedItems}
              handleItemClick={handleSpeedItemClick}
            />
          </Col>
          <Col span={6} style={{ display: "flex", flexDirection: "column" }}>
            <Row>
            <ServiceCard
              title={"Existing Internet Connectivity"}
              items={internetItems}
              handleItemClick={handleInternetItemClick}
            />
            </Row>
            <Row style={{marginTop:'10px'}}>
            <ServiceCard
              title={"Privacy"}
              items={privacyItems}
              handleItemClick={handlePrivacyItemClick}
            />
            </Row>
           
          </Col>
          <Col span={6}>
            
         
            <ServiceCard
              title={"Technology Preference"}
              items={technologyItems}
              handleItemClick={handleTechnologyItemClick}
            />
          </Col>
         

          
        
        </Row>

        <ConfirmButton
          isActive={ true}
          handleClick={() => handleConfirmButtonClick()}
        />
      </AppContent>
    </HomeLayout>
  );
}

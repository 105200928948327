import React from "react";

import { Row, Col,Flex } from "antd";
import ActionButton from "../../components/Button/ActionButton";
import LoadIcon from "../../components/Icons/LoadIcon";
import LogoIcon from "../../components/Icons/LogoIcon";
import {
    LeftCircleFilled,
    SaveOutlined,
    SettingOutlined
  } from '@ant-design/icons';
import { useNavigate,useLocation  } from "react-router-dom";
import paths from "../../routes/route";

function HomeHeader() {
  const handleSaveButtonClick = () => {
    // Your save button click logic here
    console.log("Save button clicked!");
    navigate(-1);
  };
  

  const navigate = useNavigate();
  const location = useLocation();
  // Access the current URL from the location object
  const currentUrl = location.pathname;
  return (
    <Row  align="middle" justify="flex-start" style={{marginBottom:'5px'}}>
      <Col span={8} >
        <LogoIcon  />
      </Col>

      <Col span={10}>
      {currentUrl!=paths.HOME ?<ActionButton
            buttonText="Back"
            icon={<LeftCircleFilled style={{ fontSize: '16px', color: '#fff' }} />}
           onClick={handleSaveButtonClick}
          />:''}
      </Col>
      {/* Action Buttons Column (1/3) */}
      <Col span={6}>
      <Flex wrap="wrap" gap="small">
          <ActionButton
            buttonText="Save"
            icon={<SaveOutlined style={{ fontSize: '16px', color: '#fff' }} />}
            onClick={handleSaveButtonClick}
          />
          <ActionButton
            buttonText="Load"
            icon={<LoadIcon />}
            onClick={handleSaveButtonClick}
          />
          <ActionButton
            buttonText="Preferences"
            icon={<SettingOutlined />}
            onClick={handleSaveButtonClick}
          />
        </Flex>
      </Col>
    </Row>
  );
}

export default HomeHeader;

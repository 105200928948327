// TerrainSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';

export const vegetationSlice = createSlice({
  name: 'vegetation',
  initialState: {
   
    vegetationHeight:'',
    jsonVegetationHeight:{
       "type": "string",
            "result":0
    }
  },
  reducers: {
    
    setVegetationHeight: (state, action) => {
      const { value } = action.payload;
      state.vegetationHeight = value;
      if(value>50){
        message.error("The average vegetation height cannot be higher than 50 meters");
        state.vegetationHeight = 50;
      }
     
      state.jsonVegetationHeight =  {
       
        type: "int",
        result: value
      };
    },
  },
});

export const { setVegetationHeight} = vegetationSlice.actions;
export const selectVegetationHeight = state => state.vegetation.vegetationHeight;
export const selectJsonVegetationHeight = state => state.vegetation.jsonVegetationHeight;

export default vegetationSlice.reducer;

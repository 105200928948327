import React from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';

const DistanceInput = ({value,onChange}) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <Input
      value={value}

      onChange={(e) => onChange(e.target.value)}
        placeholder="Enter value"
        style={{ width: '100%', marginBottom: '8px' }}
      />
      <div style={{ color: '#1D1D1D', fontSize: '20px',fontWeight:'700' }}>m</div>
    </div>
  );
};
DistanceInput.propTypes = {
  //onSelect: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.any
};
export default DistanceInput;

import React from "react";

import PropTypes from "prop-types";
import { Button } from "antd";

const NextButton = ({handleClick,isActive}) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {/* Your other header content */}
      <div style={{ marginLeft: "auto" }}></div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
          marginTop:'5px',
          minHeight:'20px'
        }}
      >
        <Button type="primary"  onClick={handleClick} style={{minHeight:'40px',backgroundColor:isActive?'black':'rgba(0, 103, 138, 0.70)'}} disabled={!isActive??true}>
        <span style={{ color: "#FFF", padding: "5px",fontSize:'15px',fontWeight:'700' }}>Next Question</span>
        </Button>
      </div>
    </div>
  );
};

NextButton.propTypes = {
  icon: PropTypes.element,
  isActive: PropTypes.bool,
  handleClick: PropTypes.func
};

export default NextButton;

import React from 'react';
import { Row, Col, Card, Typography } from 'antd';

const { Title } = Typography;

const DroneSolutionsCanvas = () => {
  const data = {
    keyPartners: [
      'Internet service providers ISP',
      'Technology and Data providers',
      'Application providers (data analytics)',
      'Financial providers (e.g. banks)',
      'Government and Regulatory bodies',
    ],
    keyActivities: [
      'Develop, test & validate drone based solutions in the agricultural sector having all the necessary radio & edge technologies',
      'Real-time pest detection',
      'Automated area surveillance',
      'Autonomous Driving (Agricultural sector)',
      'Real-time crop monitoring',
    ],
    valuePropositions: [
      'Extend the service offerings of local agriculture and SMEs in the agriculture sector',
      'Optimize on-situ operations in the agricultural sector.',
      'Reduce costs for agricultural practices (e.g. fertilisers)',
      'Protect biodiversity and the environment through optimised agricultural practices',
      'Upgrade network quality and speed for a positive impact in the agriculture sector',
      'Reduce costs for agricultural practices',
      'Familiarize drone users with technologies or test their solutions in a sandbox environment',
    ],
    customerRelationships: ['Personal assistance', 'Co-creation (tailor made)'],
    customerSegments: [
      'Drone operators/users',
      'Local operators',
      'Public authorities',
      'Agricultural sector',
      'SMEs: Agriculture',
    ],
    channels: ['Social media channels', 'Website'],
    keyResources: [
      'Human resources (e.g. technicians, engineers, IT professionals)',
      'Physical resources (e.g. drones, devices, cameras)',
    ],
    costStructure: ['Fixed costs for Infrastructure (Owned Drones) and maintenance', 'Operational costs'],
    revenueStreams: [
      'Increased profitability via increased production',
      'Increased profitability via cost reductions',
      'Selling Agricultural products (B2B/B2C)',
      'Monetise the use of drones and drone center for testing/evaluating services & solutions. (Pay per hour/monthly/per lesson)',
      'Drone rental',
    ],
  };

  return (
    <div>
      <Title level={2}>Drones Operation</Title>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Card title="Key Partners">
            <ul>
              {data.keyPartners.map((partner, index) => (
                <li key={index}>{partner}</li>
              ))}
            </ul>
          </Card>
        </Col>

        <Col span={8}>
          <Card title="Key Activities">
            <ul>
              {data.keyActivities.map((activity, index) => (
                <li key={index}>{activity}</li>
              ))}
            </ul>
          </Card>
        </Col>

        <Col span={8}>
          <Card title="Value Propositions">
            <ul>
              {data.valuePropositions.map((proposition, index) => (
                <li key={index}>{proposition}</li>
              ))}
            </ul>
          </Card>
        </Col>

        <Col span={8}>
          <Card title="Customer Relationships">
            <ul>
              {data.customerRelationships.map((relationship, index) => (
                <li key={index}>{relationship}</li>
              ))}
            </ul>
          </Card>
        </Col>

        <Col span={8}>
          <Card title="Customer Segments">
            <ul>
              {data.customerSegments.map((segment, index) => (
                <li key={index}>{segment}</li>
              ))}
            </ul>
          </Card>
        </Col>

        <Col span={8}>
          <Card title="Channels">
            <ul>
              {data.channels.map((channel, index) => (
                <li key={index}>{channel}</li>
              ))}
            </ul>
          </Card>
        </Col>

        <Col span={12}>
          <Card title="Key Resources">
            <ul>
              {data.keyResources.map((resource, index) => (
                <li key={index}>{resource}</li>
              ))}
            </ul>
          </Card>
        </Col>

        <Col span={12}>
          <Card title="Cost Structure">
            <ul>
              {data.costStructure.map((cost, index) => (
                <li key={index}>{cost}</li>
              ))}
            </ul>
          </Card>
        </Col>

        <Col span={24}>
          <Card title="Revenue Streams">
            <ul>
              {data.revenueStreams.map((stream, index) => (
                <li key={index}>{stream}</li>
              ))}
            </ul>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DroneSolutionsCanvas;
